<template>
  <div>
    <div v-if="screenType === 'desktop'">
      <div>
        <q-b-header></q-b-header>
        <v-card color="#f2f3f7">
          <v-card-text>
            <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page="5"
                class="elevation-1"
            >
              <template v-slot:item.Date="{item}">
                <span>{{ item.created_at.substr(0, 10) }}</span>
              </template>
              <template v-slot:item.test="{item}">
                <span>{{ item.id }}</span>
              </template>
              <template v-slot:item.score="{item}">
                <span v-if="item.score >= 70"><v-btn color="#39bf88">{{ item.score }}</v-btn></span>
                <span v-else-if="70 > item.score >= 40"><v-btn color="#fdbd68">{{ item.score }}</v-btn></span>
                <span v-else><v-btn color="#f1803f">{{ item.score }}</v-btn></span>
              </template>
              <template v-slot:item.result="{item}">
                <a @click="goToReview(item.id)">
                  <v-icon class="icon-view-hover-orange">mdi-eye</v-icon>
                </a>
                <a class="mx-1" @click="showHistory(item.id)">
                  <v-icon class="icon-view-hover-green">mdi-receipt-text-check-outline</v-icon>
                </a>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-overlay
            :z-index="zIndex"
            :value="overlayEye"
        >
          <v-row>
            <v-col cols="3">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="7">
                      Score:
                    </v-col>
                    <v-col cols="5">
                      <v-btn x-small color="#39bf88">{{ viewItemsExtra.score }}</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="7">
                      Mode:
                    </v-col>
                    <v-col cols="5">
                      <v-btn x-small color="#f1803f" v-if="viewItemsExtra.mode === 'time'">timed</v-btn>
                      <v-btn x-small color="#f1803f" v-else-if="viewItemsExtra.mode === 'tutor'">Tutor</v-btn>
                      <v-btn x-small color="#f1803f" v-else-if="viewItemsExtra.mode === 'liveTutor'">Live Tutor</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="7">
                      Correct:
                    </v-col>
                    <v-col cols="5">
                      <v-btn x-small color="#39bf88">{{ viewItemsExtra.correct }}</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="7">
                      Incorrect:
                    </v-col>
                    <v-col cols="5">
                      <v-btn x-small color="red">{{ viewItemsExtra.incorrect }}</v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="9">
              <v-card>
                <v-card-actions>
                  <v-row>
                    <v-col md="11" sm="10"></v-col>
                    <v-col md="1" sm="2">
                      <a @click="overlayEye=!overlayEye">
                        <v-icon color="red">mdi-close</v-icon>
                      </a>
                    </v-col>
                  </v-row>
                </v-card-actions>
                <v-card-text>
                  <v-data-table
                      :headers="viewHeaders"
                      :items="viewItems"
                      :items-per-page="5"
                      class="elevation-1"
                  >
                    <template v-slot:item.questions="{item}">
                      <a v-if="item.correct"><span class="mr-3"
                                                   style="font-family: Blinker-SemiBold!important;font-size:20px">{{
                          item.questions
                        }}</span>
                        <v-icon color="#39bf88">mdi-check</v-icon>
                      </a>
                      <a v-else><span class="mr-3"
                                      style="font-family: Blinker-SemiBold!important;font-size:20px">{{
                          item.questions
                        }}</span>
                        <v-icon color="red">mdi-close</v-icon>
                      </a>
                    </template>
                    <template v-slot:item.seen="{item}">
                      <a>
                        <v-icon class="icon-view-hover" color="#39bf88" @click="showQuestion(item.id)">mdi-eye</v-icon>
                      </a>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-overlay>
        <v-overlay
            :z-index="zIndex2"
            :value="overlayEye2"
        >
          <v-row>
            <v-col cols="8" offset="2">
              <v-card>
                <v-card-actions>
                  <v-row>
                    <v-col md="11" sm="10"></v-col>
                    <v-col md="1" sm="2">
                      <a @click="closeQuestion()">
                        <v-icon color="red">mdi-close</v-icon>
                      </a>
                    </v-col>
                  </v-row>
                </v-card-actions>
                <v-divider></v-divider>
                <div v-if="singleQuestion.question">
                  <v-card-title class="px-16">
                    Question : {{ ' ' + singleQuestion.question['title'] }}
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row>
                      <v-col cols="1"></v-col>
                      <v-col cols="10">
                        <v-row class="my-8">
                          <span style="font-size:19px">Answers:</span>
                        </v-row>
                        <v-row class="my-8" v-for=" (answer,index) in singleQuestion.answers" :key="answer.id">
                          <p v-if="answer['is_true']" style="color:green;font-size:20px">
                            <span class="mr-1" v-if="singleQuestion.userAnswerId === answer['id'] "><v-icon>mdi-radiobox-marked</v-icon></span>
                            {{ index + 1 }}:{{ answer['content'] }}
                            <span class="mr-1" v-if="singleQuestion.userAnswerId === answer['id'] "
                                  style="color: #f1803f">(You have chosen this answer)</span>
                          </p>
                          <p v-else style="color:red;font-size:20px">
                            <span class="mr-1" v-if="singleQuestion.userAnswerId === answer['id'] "><v-icon>mdi-radiobox-marked</v-icon></span>
                            {{ index + 1 }}:{{ answer['content'] }}
                            <span class="mr-1" v-if="singleQuestion.userAnswerId === answer['id'] "
                                  style="color: #f1803f">(You have chosen this answer)</span>
                          </p>
                        </v-row>
                      </v-col>
                      <v-col cols="1"></v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-subtitle>
                    <v-row>
                      <v-col md="2" sm="4">
                        <a @click="closeQuestion">
                          <v-icon large>mdi-arrow-left</v-icon>
                        </a>
                      </v-col>
                      <v-col md="10" sm="8">
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-overlay>
      </div>
    </div>
    <div v-else>
      <div style="margin-bottom: 180px!important">
        <q-b-header-mobile></q-b-header-mobile>
        <v-card color="#f2f3f7">
          <v-card-text>
            <v-data-table
                :headers="headers"
                :items="items"
                :items-per-page="5"
                class="elevation-1"
            >
              <template v-slot:item.Date="{item}">
                <span>{{ item.created_at.substr(0, 10) }}</span>
              </template>
              <template v-slot:item.test="{item}">
                <span>{{ item.id }}</span>
              </template>
              <template v-slot:item.score="{item}">
                <span v-if="item.score >= 70"><v-btn color="#39bf88">{{ item.score }}</v-btn></span>
                <span v-else-if="70 > item.score >= 40"><v-btn color="#fdbd68">{{ item.score }}</v-btn></span>
                <span v-else><v-btn color="#f1803f">{{ item.score }}</v-btn></span>
              </template>
              <template v-slot:item.result="{item}">
                <a @click="goToReview(item.id)">
                  <v-icon class="icon-view-hover-orange">mdi-eye</v-icon>
                </a>
                <a class="mx-1" @click="showHistory(item.id)">
                  <v-icon class="icon-view-hover-green">mdi-receipt-text-check-outline</v-icon>
                </a>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-overlay
            :z-index="zIndex"
            :value="overlayEye"
        >
          <v-row>
            <v-col cols="12">
              <v-card>
                <v-card-text>
                  <v-row>
                    <v-col cols="7">
                      Score:
                    </v-col>
                    <v-col cols="5">
                      <v-btn x-small color="#39bf88">{{ viewItemsExtra.score }}</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="7">
                      Mode:
                    </v-col>
                    <v-col cols="5">
                      <v-btn x-small color="#f1803f" v-if="viewItemsExtra.mode === 'time'">timed</v-btn>
                      <v-btn x-small color="#f1803f" v-else-if="viewItemsExtra.mode === 'tutor'">Tutor</v-btn>
                      <v-btn x-small color="#f1803f" v-else-if="viewItemsExtra.mode === 'liveTutor'">Live Tutor</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="7">
                      Correct:
                    </v-col>
                    <v-col cols="5">
                      <v-btn x-small color="#39bf88">{{ viewItemsExtra.correct }}</v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="7">
                      Incorrect:
                    </v-col>
                    <v-col cols="5">
                      <v-btn x-small color="red">{{ viewItemsExtra.incorrect }}</v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card>
                <v-card-actions>
                  <v-row>
                    <v-col md="11" sm="10"></v-col>
                    <v-col md="1" sm="2">
                      <a @click="overlayEye=!overlayEye">
                        <v-icon color="red">mdi-close</v-icon>
                      </a>
                    </v-col>
                  </v-row>
                </v-card-actions>
                <v-card-text>
                  <v-data-table
                      :headers="viewHeaders"
                      :items="viewItems"
                      :items-per-page="5"
                      class="elevation-1"
                  >
                    <template v-slot:item.questions="{item}">
                      <a v-if="item.correct"><span class="mr-3"
                                                   style="font-family: Blinker-SemiBold!important;font-size:20px">{{
                          item.questions
                        }}</span>
                        <v-icon color="#39bf88">mdi-check</v-icon>
                      </a>
                      <a v-else><span class="mr-3"
                                      style="font-family: Blinker-SemiBold!important;font-size:20px">{{
                          item.questions
                        }}</span>
                        <v-icon color="red">mdi-close</v-icon>
                      </a>
                    </template>
                    <template v-slot:item.seen="{item}">
                      <a>
                        <v-icon class="icon-view-hover" color="#39bf88" @click="showQuestion(item.id)">mdi-eye</v-icon>
                      </a>
                    </template>
                  </v-data-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-overlay>
        <v-overlay
            :z-index="zIndex2"
            :value="overlayEye2"
        >
          <v-row>
            <v-col cols="8" offset="2">
              <v-card>
                <v-card-actions>
                  <v-row>
                    <v-col md="11" sm="10"></v-col>
                    <v-col md="1" sm="2">
                      <a @click="closeQuestion()">
                        <v-icon color="red">mdi-close</v-icon>
                      </a>
                    </v-col>
                  </v-row>
                </v-card-actions>
                <v-divider></v-divider>
                <div v-if="singleQuestion.question">
                  <v-card-title class="px-16">
                    Question : {{ ' ' + singleQuestion.question['title'] }}
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-card-text>
                    <v-row>
                      <v-col cols="1"></v-col>
                      <v-col cols="10">
                        <v-row class="my-8">
                          <span style="font-size:19px">Answers:</span>
                        </v-row>
                        <v-row class="my-8" v-for=" (answer,index) in singleQuestion.answers" :key="answer.id">
                          <p v-if="answer['is_true']" style="color:green;font-size:20px">
                            <span class="mr-1" v-if="singleQuestion.userAnswerId === answer['id'] "><v-icon>mdi-radiobox-marked</v-icon></span>
                            {{ index + 1 }}:{{ answer['content'] }}
                            <span class="mr-1" v-if="singleQuestion.userAnswerId === answer['id'] "
                                  style="color: #f1803f">(You have chosen this answer)</span>
                          </p>
                          <p v-else style="color:red;font-size:20px">
                            <span class="mr-1" v-if="singleQuestion.userAnswerId === answer['id'] "><v-icon>mdi-radiobox-marked</v-icon></span>
                            {{ index + 1 }}:{{ answer['content'] }}
                            <span class="mr-1" v-if="singleQuestion.userAnswerId === answer['id'] "
                                  style="color: #f1803f">(You have chosen this answer)</span>
                          </p>
                        </v-row>
                      </v-col>
                      <v-col cols="1"></v-col>
                    </v-row>
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-subtitle>
                    <v-row>
                      <v-col md="2" sm="4">
                        <a @click="closeQuestion">
                          <v-icon large>mdi-arrow-left</v-icon>
                        </a>
                      </v-col>
                      <v-col md="10" sm="8">
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-overlay>
      </div>
    </div>
  </div>

</template>

<script>
import QBHeader from "../components/QBHeader";
import QBHeaderMobile from "../components/QBHeaderMobile.vue";

export default {
  components: {
    QBHeaderMobile,
    QBHeader
  },
  data() {
    return {
      screenType: null,
      exam: null,
      counterItems: 0,
      overlayEye: false,
      overlayEye2: false,
      overlayPage: false,
      zIndex: 5,
      singleQuestion: [],
      zIndex2: 9,
      score: 0,
      correct: 0,
      incorrect: 0,
      viewItemsExtra: [],
      headers: [
        {
          text: 'Date',
          align: 'start',
          sortable: true,
          value: 'Date',
        },
        {text: 'Test-ID', value: 'test'},
        {text: 'Score', value: 'score'},
        {text: 'Mode', value: 'type'},
        {text: 'Chapter', value: 'chapter'},
        {text: 'Questions', value: 'questions'},
        {text: 'Result', value: 'result'},
      ],
      viewHeaders: [
        {
          text: 'Questions',
          align: 'start',
          sortable: true,
          value: 'questions',
        },
        {text: 'Chapter', value: 'chapter'},
        {text: 'Section', value: 'section'},
        {text: 'Topic', value: 'topic'},
        {text: 'Seen', value: 'seen'},
      ],
      items: [],
      viewItems: [],
    }
  },
  mounted() {
    this.getScreenType()
    window.axios.get('api/exam/prior').then((res) => {
      this.items = res.data.data
    }).catch((err) => {
      console.log(err)
    })
  },
  methods: {
    getScreenType() {
      this.screenType = window.screen.width < 500 ? 'mobile' : 'desktop'
    },
    goToReview(id) {
      window.axios.get('api/exam/details/single/get/' + id).then((res) => {
        this.$router.push({
          name: 'ReviewTest',
          params: res.data.data
        })
      })
    },
    showQuestion(id) {
      this.overlayEye = false
      this.overlayEye2 = true
      window.axios.get('api/exam/question/' + id).then((res) => {
        this.singleQuestion = res.data.data
      })
    },
    closeQuestion() {
      this.overlayEye = true
      this.overlayEye2 = false
    },
    showHistory(id) {
      window.axios.get('api/exam/passed/detail/' + id).then((res) => {
        this.viewItems = res.data.data.data
        this.viewItemsExtra['score'] = res.data.data.score
        this.viewItemsExtra['mode'] = res.data.data.mode
        this.viewItemsExtra['correct'] = res.data.data.correct
        this.viewItemsExtra['incorrect'] = res.data.data.incorrect
      })
      this.overlayEye = true
    }
  }
}
</script>

<style scoped>
@font-face {
  font-family: 'Blinker-Bold';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-Regular';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Blinker-SemiBold';
  font-style: normal;
  src: url('../assets/fonts/blinker/Blinker-SemiBold.ttf') format('truetype');
}


.icon-view-hover-orange:hover {
  color: #f1803f !important;
}

.icon-view-hover-green:hover {
  color: #34c288 !important;
}
</style>